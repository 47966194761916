// 专项保护管理法规、规章
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                prop: 'MC',
                label: '名称'
            }, {
                prop: 'LB',
                label: '类别'
            }, {
                prop: 'GBSJ',
                label: '公布时间',
                minWidth: 105,
                sortable: 'custom'
            }, {
                prop: 'ZT',
                label: '状态'
            }, {
                prop: 'LJ',
                label: '附件',
                showFileBtn: true,
            }],
        },
    },
    snSearch: {
        selectData: [{
            label: '类别',
            clearable: true,
            placeholder: "请选择类别",
            list: [{
                ID: '0',
                NAME: '地方性法规'
            }, {
                ID: '1',
                NAME: '地方政府规章'
            }, {
                ID: '2',
                NAME: '地方规范性文件'
            }, {
                ID: '3',
                NAME: '保护管理机构内部制度'
            }, {
                ID: '4',
                NAME: '其他'
            }],
            optionLabel: 'NAME',
            optionValue: 'ID',
            value: 'LB',
            operateType: 'search',
            isShow: true
        }, {
            label: '状态',
            clearable: true,
            placeholder: "请选择状态",
            list: [{
                ID: '0',
                NAME: '生效'
            }, {
                ID: '1',
                NAME: '废止'
            }],
            optionLabel: 'NAME',
            optionValue: 'ID',
            value: 'ZT',
            operateType: 'search',
            isShow: true
        }]
    }
};
export default selfData;